/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

a {
  @apply text-blue-500;
}

.ant-checkbox-wrapper {
  font-size: inherit;
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector {
  height: 4rem;
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector::after,
.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-item,
.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-placeholder {
  line-height: 4rem;
}

.ant-select-selection-item-content {
  @apply text-xs;
  @apply font-light;
  line-height: 21px;
}

.ant-select-multiple .ant-select-selection-item-content {
  margin-right: 6px !important;
}

.ant-select-multiple.ant-select-lg .ant-select-selection-search-input,
.ant-select-multiple.ant-select-lg .ant-select-selection-search-mirror {
  height: 3.2rem;
  line-height: 3rem;
}

.ant-select-multiple.ant-select-lg .ant-select-selection-search {
  height: 3.3rem;
  line-height: 3.3rem;
}

.ant-select-multiple.ant-select-lg .ant-select-selector::after {
  line-height: 3.8rem;
}

.ant-select-multiple .ant-select-selector::after {
  margin: 0;
}

.ant-btn {
  display: flex !important;
  @apply items-center justify-center;
}

.ant-select-multiple.ant-select-lg .ant-select-selection-item {
  height: 3.2rem;
  line-height: 3.2rem;
}

.ant-tabs {
  font-size: inherit;
}

.ant-select-item {
  font-size: inherit;
}

.ant-select-dropdown {
  font-size: inherit;
}

.ant-tabs-tab {
  font-size: inherit;
  margin: 0 3.2rem 0 0;
}

.ant-select {
  font-size: inherit;
}

.ant-table {
  font-size: inherit;
  @apply rounded-xl !important;
  --tw-shadow: 0 2px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 2px -1px rgba(0, 0, 0, 0.06) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.ant-table-thead > tr > th {
  @apply bg-white !important;
  @apply rounded-t-xl !important;
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  padding: 10px 10px !important;
}

.ant-table-tbody > tr.ant-table-row:hover > td {
  background: #ffffff !important;
}

.ant-pagination {
  font-size: inherit;
}

.primary-tabs .ant-tabs-nav {
  /* width: 36rem; */
  z-index: 99999;
}

.w-100 {
  width: 100%;
}

.w-12rem {
  width: 12rem;
}

.w-72rem {
  width: 72rem;
}

.main-container {
  display: flex;
  flex-direction: column;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.content-area-wrapper {
  margin: 0 1.5rem;
}

.bs-popover-right {
  margin-top: 5rem !important;
  margin-right: 4rem !important;
}

.popover {
  max-width: 90rem;
  min-height: 30rem;
  max-height: 40rem;
  overflow-y: auto;
}

.justify-space-btw {
  display: flex;
  justify-content: space-between;
}

.align-baseline {
  align-items: baseline;
}

.margin-top-0-5r {
  margin-top: 0.5rem;
}

.cursor-pointer {
  cursor: pointer;
}

.width-5p {
  width: 0.5rem;
}

.ant-layout-sider {
  flex: 0 0 auto !important;
  max-width: 40rem !important;
  min-width: 38rem !important;
  width: auto !important;
  background-color: white;
}

.ant-layout-sider-collapsed {
  flex: 0 0 4rem !important;
  max-width: 4rem !important;
  min-width: 4rem !important;
  width: 4rem !important;
}

.header-title {
  color: #fff;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-right: 1rem;
  font-size: 2.1rem;
}

.header-title a {
  color: #fff;
}

.header-title a:hover {
  color: #dddddd;
}

.reactour__close:focus {
  outline: none;
}

.ant-layout {
  background: transparent !important;
}

.margin-right-5p {
  margin-right: 0.5rem;
}

.margin-right-20p {
  margin-right: 2rem;
}

.margin-top-10p {
  margin-top: 1rem;
}

.margin-bottom-20p {
  margin-bottom: 2rem;
}

.margin-top-20p {
  margin-top: 2rem;
}

.margin-top-50p {
  margin-top: 5rem;
}

.margin-left-1r {
  margin-left: 1rem;
}

.margin-2r {
  margin: 2rem;
}

.margin-5r {
  margin: 5rem;
}

.row-direction-column {
  flex-direction: column;
}

.padding-10p {
  padding: 1rem;
}

.min-w-25r {
  min-width: 25rem;
}

.padding-bottom-100p {
  padding-bottom: 100px;
}

.padding-left-20p {
  padding-left: 2rem;
}

.padding-right-10p {
  padding-right: 1rem;
}

.padding-right-20p {
  padding-right: 2rem;
}

.padding-bottom-2r {
  padding-bottom: 2rem;
}

.padding-top-0-5r {
  padding-top: 0.5rem;
}

.zero-padding {
  padding: 0;
}

.zero-margin-bottom {
  margin-bottom: 0;
}

.margin-left-0-2r {
  margin-left: 0.2rem;
}

.slider-input-margin {
  margin: 0px 1rem;
}

.ant-slider-track {
  background-color: #018cff;
}

.ant-slider-handle {
  border: solid 2px #018cff;
}

.min-height-100vh {
  min-height: 100vh;
}

.justify-center {
  display: flex;
  justify-content: center;
}

.justify-end {
  display: flex;
  justify-content: flex-end;
}

.padding-1-5r {
  padding: 1.5rem;
}

.ant-tooltip {
  z-index: 999999;
}

.ant-tabs-ink-bar {
  background-color: #018cff;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #018cff;
}

.query-select {
  max-width: 36rem;
}

.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
  border-bottom: 0.1rem solid #a0a0a0;
}

.ant-select-lg {
  font-size: 1.4rem;
}

.ant-tabs-tab {
  padding: 0 1.2rem 1.2rem 1.2rem;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #018cff;
  border-color: #018cff;
}

.ant-select-multiple .ant-select-selection-item {
  color: #ffffff;
  background: #018cff;
  border-radius: 0.5rem;
}

.ant-collapse-header {
  @apply h-10;
}

.ant-collapse-content {
  @apply px-4 overflow-auto;
  max-height: calc(100vh - 200px - 142px);
}

@media (min-width: 768px) {
  .ant-collapse-content {
    max-height: calc(100vh - 200px - 64px);
  }
}

.hidden-query-area {
  display: none;
  height: 100%;
}

.ant-collapse-content {
  box-shadow: inset -5px 6px 6px -3px rgba(0, 0, 0, 0.06),
    inset -5px -6px 6px -3px rgba(0, 0, 0, 0.04);
}

.show-query-area {
  height: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.show-query-area::-webkit-scrollbar {
  display: none;
}

.graph-wrapper {
  position: relative;
}

.ant-input-number,
.ant-card-bordered,
.ant-select-multiple .ant-select-selector,
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 0.5rem !important;
  background-color: #ffffff !important;
  @apply shadow-md;
}

.ant-input-number-handler-wrap {
  border-radius: 0.5rem !important;
}

.ant-input-number {
  border-radius: 0.3rem;
}

.center-spinner {
  display: flex;
  justify-content: center;
  margin-top: 20rem;
  min-width: 20rem;
  width: calc(100vw - 50rem);
}

div[data-tour-elem="controls"] {
  justify-content: space-around;
}

.not-found-wrapper {
  background: #ececec;
  min-height: 20vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.align-center {
  align-items: center;
}

.text-center {
  text-align: center;
}

.primary-tabs .ant-tabs-content {
  border: 0.1rem solid #ccc;
  border-radius: 0.6rem;
}

.secondary-tabs .ant-tabs-nav {
  width: 35rem;
}

.secondary-tabs .ant-tabs-content-holder {
  margin-bottom: 1.2rem;
}

.graph-node-modal {
  min-width: 20rem;
  max-width: 40rem;
  position: absolute !important;
  overflow-y: auto;
  top: 0;
}

.data-type-options .ant-card-body {
  padding: 0;
  padding-top: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.ant-card {
  @apply bg-blue-200;
}

.ant-card-body {
  @apply flex flex-col flex-1 space-y-4 overflow-y-auto;
}

.export-buttons {
  display: flex;
  flex-wrap: wrap;
}

.export-buttons button,
.export-buttons a {
  margin-left: 1rem;
  margin-top: 1rem;
}

svg[name="svg-container-graph1"] {
  min-height: calc(80vh - 5rem) !important;
  width: 100% !important;
}

.site-layout-background {
  min-height: 23rem;
  margin-top: 1rem;
}

.sider-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #fafafa;
  padding-top: 2rem;
}

.sider-wrapper-shorten {
  display: flex;
  flex: 0 0;
  background-color: #fafafa;
  animation-duration: 0.7s;
  animation-name: slidein1;
  border-right: 0.1rem solid #cccccc;
}

.sider-wrapper-shorten .ant-layout-sider {
  animation-duration: 1s;
  animation-name: slidein;
}

.center-header {
  width: 100%;
  display: flex;
  justify-content: center;
}

.left-header {
  width: 100%;
  display: flex;
  color: #fff;
  justify-content: space-between;
}

.nav-link-item {
  color: #fff;
  margin-left: 1rem;
}

.nav-link-item:hover {
  color: #ccc;
}

.ant-btn {
  border-radius: 0.5rem !important;
}

.ant-btn-primary:focus {
  background: #2555ac !important;
}

.ant-pagination-item-link {
  @apply rounded-lg !important;
  @apply shadow-md !important;
  border: 0 !important;
}

.ant-pagination-item {
  @apply rounded-lg !important;
  @apply shadow-md !important;
  border: 0 !important;
}

.about-img-item {
  width: 40%;
  height: auto;
}

.explanation-texts a {
  margin-left: 0.3rem;
  margin-right: 0.3rem;
}

.browser-table .ant-pagination {
  display: none;
}

.explanation-texts {
  overflow-x: hidden;
}

.legend-node {
  border-radius: 20rem;
  width: 2.2rem;
  height: 2.2rem;
  margin-top: 1rem;
}

.legend-link {
  width: 9rem;
  height: 4px;
  margin-top: 1rem;
}

.legend-divider {
  border-bottom: 2px solid #cccccc;
  box-sizing: content-box;
  margin-top: 2rem;
  width: 100%;
}

.wrap {
  flex-wrap: wrap;
}

.show-links-wrapper {
  position: absolute;
  width: 100%;
  top: -4.2rem;
}

.primary-tabs .ant-tabs-top > .ant-tabs-nav {
  margin: 0 0 1.5rem 0;
}

ol {
  counter-reset: item;
}

ol > li {
  counter-increment: item;
}

ol ol > li {
  display: block;
}

ol ol > li:before {
  content: counters(item, ".") ". ";
  margin-left: -20px;
}

.slide-img-item {
  width: 60%;
  height: auto;
}

.content-table-item {
  color: #2555ac;
  cursor: pointer;
  display: inline-block;
}

.citation-tab li {
  margin-top: 1rem;
}

.tutorial-button {
  font-size: 1.9rem;
}

.no-margin-horizontal {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

@keyframes slidein1 {
  from {
    min-width: 100vw;
  }

  to {
    min-width: 35rem;
    margin-right: 0.5rem;
  }
}

@keyframes slidein {
  from {
    margin-left: 40%;
    min-width: 100vw;
  }

  to {
    margin-left: 0%;
    min-width: 30rem;
  }
}

body {
  background: radial-gradient(
    136.96% 135.04% at 0.68% 0.89%,
    #2555ac 0%,
    #742f4c 100%
  );
  background-attachment: fixed;
}

@media (max-width: 767px) {
  [data-layout="layout"] {
    width: 100%;
    .wrapper {
      @apply relative flex flex-row items-stretch;
      .left-sidebar {
        @apply absolute top-0 bottom-0 left-0 z-10 w-64 h-full;
        @apply transition-transform duration-300 ease-in-out transform -translate-x-64;
      }
      .main {
        @apply z-0 flex flex-col w-full;
      }
    }
    &[data-collapsed="true"] {
      .left-sidebar {
        @apply translate-x-0;
      }
    }
  }
}

@media (min-width: 768px) {
  [data-layout="layout"] {
    width: 100%;
    .wrapper {
      @apply flex flex-row items-stretch;
      .left-sidebar {
        @apply w-full;
        min-width: calc(theme("spacing.1") * 16);
        max-width: calc(theme("spacing.1") * 64);
        transition: max-width 0.3s ease-in-out;
      }
      .main {
        min-width: calc(100% - (theme("spacing.1") * 64));
        @apply flex flex-col;
      }
    }
    &[data-collapsed="false"] {
      .left-sidebar {
        max-width: calc(theme("spacing.1") * 64);
      }
    }
    &[data-collapsed="true"] {
      .left-sidebar {
        max-width: calc(theme("spacing.1") * 16);
      }
    }
  }
}

.blue-green-gradiant {
  background: radial-gradient(
    136.96% 135.04% at 0.68% 0.89%,
    #1baeb8 0%,
    #112179 100%
  );
}
.button-curve {
  border: 1px solid transparent;
}
.button-curve:hover {
  border: 1px solid #0369a1;
  box-sizing: border-box;
  border-radius: 45px;
  color: #0369a1;
}
.navbar-button-active {
  border-radius: 45px;
  background: #0369a1;
  color: white;
}
.button-search {
  border: 1px solid #0369a1;
  box-sizing: border-box;
  border-radius: 10px;
  color: #0369a1;
}
.button-search:disabled {
  pointer-events: none;
  opacity: 0.5;
  border-color: gray;
  color: gray;
}
.button-search:hover {
  cursor: pointer;
  border: 1px solid #0369a1;
  box-sizing: border-box;
  border-radius: 10px;
  background: #0369a1;
  color: white;
}

.unselectable {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.rounded-select.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  @apply items-center;
  border-radius: 0.5rem !important;
}
.ant-divider {
  border-top-width: 2px;
}
.rounded-select.ant-select-multiple .ant-select-selection-item {
  @apply self-center leading-3 text-white rounded-md;
  background-color: #0369a1;
}

.rounded-select.ant-select-multiple .ant-select-selection-item-remove {
  @apply self-center leading-3 text-white;
}

.ant-select-multiple .ant-select-selection-item-remove > .anticon {
  vertical-align: 0.1em !important;
}

.ant-select-multiple.ant-select-lg .ant-select-selection-item {
  height: 25px !important;
}

.ant-tabs {
  height: 100%;
}

.ant-tabs-content-holder {
  display: flex;
}

.ant-select-selection-item {
  @apply font-light;
}

.ant-form-item {
  @apply font-semibold;
}

[data-navbar="light"] {
  .navbar {
    @apply text-gray-700;
    @apply bg-white border-gray-100;
    .navbar-search {
      input {
        @apply bg-gray-100 border-gray-100;
      }
    }
  }
}

[data-navbar="dark"] {
  .navbar {
    @apply text-white;
    background: color(theme("colors.gray.900") tint(5%));
    border-color: color(theme("colors.gray.900") tint(10%));
    .navbar-search {
      input {
        background: color(theme("colors.gray.900") tint(10%));
        border-color: color(theme("colors.gray.900") tint(10%));
      }
    }
  }
}

.ant-layout-header {
  @apply text-gray-700;
  @apply z-50 bg-white border border-gray-100 shadow;
  @apply p-0 overflow-x-hidden h-36 md:h-16;
}

.custom-scrollbar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #dadada;
  background-color: #f5f5f5;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 0.25rem;
  height: 0.25rem;
  background-color: #f5f5f5;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #aaa;
}
